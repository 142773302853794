<template>
<div id="layout-wrapper" sticky-container>
    <NavBar />
    <SideBar :is-condensed="isMenuCondensed" />
    <!-- ============================================================== -->
    <!-- Start Page Content here -->
    <!-- ============================================================== -->

    <div class="main-content">
        <div class="page-content">
            <!-- Start Content-->
            <div class="container-fluid">
                <!-- <vue-page-transition name="fade-in-right"> -->
                <router-view />
                <!-- </vue-page-transition> -->
            </div>
        </div>
        <Footer />
    </div>
    <!--        <RightBar v-if="$can('role', 'root')"/>-->
    <RightBar />
    <!-- start modal-->
    <div>
        <b-modal size="md" :no-close-on-esc="true" :hide-header-close="true" :no-close-on-backdrop="true" id="my-modal" hide-footer hide-header>
        <h5 class="ml-1 mb-0">Cập nhật mật khẩu của bạn !</h5>
        <hr>
            <b-form>
                <div class="row px-3 mt-4 mb-3 input-pass">
                    <b-form-input  class="col-12" v-model="pass" placeholder="Nhập mật khẩu mới" :type="pass_show.pass"></b-form-input>
                    <span style="width: 0px !important;" v-show="pass_show.pass=='password'" @click="pass_show.pass = 'text'" class="eye"><i class="fa fa-eye-slash"></i></span>
                    <span style="width: 0px !important;" v-show="pass_show.pass=='text'" @click="pass_show.pass = 'password'" class="eye"><i class="fa fa-eye"></i></span>
                </div>
            </b-form>
            <b-form class="mt-2 mb-4 input-confirm-pass">
                <div class="row px-3">
                    <b-form-input class="col-12" v-model="confirmPass" placeholder="Nhập lại mật khẩu mới" :type="pass_show.confirmPass"></b-form-input>
                    <span style="width: 0px !important" v-show="pass_show.confirmPass=='password'" @click="pass_show.confirmPass = 'text'" class="eye"><i class="fa fa-eye-slash"></i></span>
                    <span style="width: 0px !important" v-show="pass_show.confirmPass=='text'" @click="pass_show.confirmPass = 'password'" class="eye"><i class="fa fa-eye"></i></span>
                </div>
            </b-form>
            <b-form class="mt-2 mx-1">
                <div class="color-warning">{{message}}</div>
            </b-form>
            <b-form class="mx-1 style-px">
                <b-button class="mt-3" variant="success" block @click="changePass" >Xác nhận</b-button>
            </b-form>
        </b-modal>
  </div>
  <!-- end modal -->
    <div>
        <b-modal size="md" hide-footer id="success-modal" title="Thông báo !">
            <b-form class="style-check">
                <span><i class="bx bx-check"></i></span>
            </b-form>
            <b-form class="mt-2 mb-4" style="text-align: center; font-size: 24px; font-weight: 600; letter-spacing: 0.1px">
                <span>Cập nhật mật khẩu thành công !</span>
            </b-form>
            <b-form class="mt-4">
                <b-button class="mt-3" variant="success" block @click="$bvModal.hide('success-modal')" >Xác nhận</b-button>
            </b-form>
        </b-modal>
    </div>
    <div>
        <b-modal size="md" hide-footer id="success-modal-err" title="Thông báo !">
            <b-form class="style-check">
                <span><i class="bx bx-check"></i></span>
            </b-form>
            <b-form class="mt-2 mb-4" style="text-align: center; font-size: 24px; font-weight: 600; letter-spacing: 0.1px">
                <span>Mật khẩu đã thay đổi ở thiết bị khác !</span>
            </b-form>
            <b-form class="mt-4">
                <b-button class="mt-3" variant="success" block @click="logOutPass()" >Xác nhận</b-button>
            </b-form>
        </b-modal>
    </div>
</div>
</template>

<script>
import {
    router
} from '../../router';

import NavBar from '../../components/nav-bar';
import SideBar from '../../components/side-bar';
import RightBar from '../../components/right-bar';
import Footer from '../../components/footer';
import { httpClient } from '../../_utils/httpClient';

export default {
    components: {
        NavBar,
        SideBar,
        RightBar,
        Footer
    },
    data() {
        return {
            isMenuCondensed: true,
            message: '',
            pass: '',
            confirmPass: '',
            pass_show: {
                pass: 'password',
                confirmPass: 'password'
            }
        };
    },
    mounted() {
        this.checkPass()
    },
    created: () => {
        document.body.removeAttribute('data-layout', 'horizontal');
        document.body.removeAttribute('data-topbar', 'dark');
        document.body.removeAttribute('data-layout-size', 'boxed');
    },
    watch: {
        '$route.name': {
            handler() {
                this.checkPass()
            }
        },
        pass() {
            let errInput = document.querySelector('.input-pass input')
            if(errInput) {
                errInput.style.border = '1px solid #ced4da'
            }
        },
        confirmPass() {
            let errInput = document.querySelector('.input-confirm-pass input')
            if(errInput) {
                errInput.style.border = '1px solid #ced4da'
            }
        }
    },
    methods: {
        checkPass() {
            let pass = localStorage.getItem('pass')
            if(pass == '') {
                this.showModal()
            }
            else localStorage.removeItem("pass")
        },
        hideModal() {
            this.$bvModal.hide('my-modal')
        },
        showModal() {
            this.$bvModal.show('my-modal')
        },
        async changePass() {
            let pass = this.pass.trim()
            let confirmPass = this.confirmPass.trim()
            if(pass ==''){
                this.message = 'Mật khẩu trống !'
                this.inputPassError()
                return
            }
            if(confirmPass =='') {
                this.message = 'Nhập lại mật khẩu trống !'
                this.inputConfirmPassError()
                return
            }
            if(pass.length < 6){
                this.message = 'Mật khẩu phải từ 6 kí tự !'
                this.inputPassError()
                return
            }
            if(confirmPass.length < 6) {
                this.message = 'Nhập lại mật khẩu phải từ 6 kí tự !'
                this.inputConfirmPassError()
                return
            }
            if(pass != confirmPass) {
                this.message = 'Mật khẩu không trùng khớp !'
                this.inputConfirmPassError()
                return
            }
            if(pass == confirmPass) {
                let payload = {
                    ...payload
                }
                payload.newPassword = pass
                payload.pass = ''
                let response = await httpClient.post(`auth/change-password`,payload)
                if(response.data.code == 0) {
                    this.$bvModal.hide('my-modal')
                    localStorage.removeItem("pass");
                    this.$bvModal.show('success-modal')
                }
                if(response.data.code == 1) {
                    localStorage.removeItem("pass");
                    this.$bvModal.show('success-modal-err')
                }
            }
        },  
        toggleMenu() {
            document.body.classList.toggle('sidebar-enable');

            if (window.screen.width >= 992) {
                // eslint-disable-next-line no-unused-vars
                router.afterEach((routeTo, routeFrom) => {
                    document.body.classList.remove('sidebar-enable');
                    document.body.classList.remove('vertical-collpsed');
                });
                document.body.classList.toggle('vertical-collpsed');
            } else {
                // eslint-disable-next-line no-unused-vars
                router.afterEach((routeTo, routeFrom) => {
                    document.body.classList.remove('sidebar-enable');
                });
                document.body.classList.remove('vertical-collpsed');
            }
            this.isMenuCondensed = !this.isMenuCondensed;
        },
        toggleRightSidebar() {
            document.body.classList.toggle('right-bar-enabled');
        },
        hideRightSidebar() {
            document.body.classList.remove('right-bar-enabled');
        },
        inputPassError() {
            let errInput = document.querySelector('.input-pass input')
            if(errInput) {
                errInput.style.border = '1px solid red';
            }
            let errInputOff = document.querySelector('.input-confirm-pass input')
            if(errInputOff) {
                errInputOff.style.border = '1px solid #ced4da'
            }
        },
        inputConfirmPassError() {
            let errInput = document.querySelector('.input-confirm-pass input')
            if(errInput) {
                errInput.style.border = '1px solid red';
            }
            let errInputOff = document.querySelector('.input-pass input')
            if(errInputOff) {
                errInputOff.style.border = '1px solid #ced4da'
            }
        },
        async logOutPass() {
            this.$bvModal.hide('success-modal-err')
            localStorage.clear()
            await this.$router.push({
                name: 'auth.login'
            })
            var result = document.getElementById('alert');
            if(result) {
                if(result.classList.includes("alert")){
                    result.classList.remove("alert");
                }
            }
        }
    },
};
</script>
<style scoped>
.color-warning {
    color: rgba(226, 2, 2, 0.956);
    font-weight: 500;
}
.eye {
    cursor: pointer;
    transform: translate(-40px, 8px);
}
#my-modal input {
    padding: 12px 50px 12px 12px !important;
}
.style-check {
    text-align: center;
}
.style-check span {
    font-size: 62px;
}
.style-check span i {
    color: #34c38f;
    border: 4px solid #34c38f;
    border-radius: 50%;
    transform: scale(1.1);
}
.style-mx {
    margin-left: 162px;
    margin-right: 162px;
}
</style>
