<template>
    <header id="page-topbar">
        <div class="navbar-header">
            <div class="d-flex">
                <!-- LOGO -->
                <div class="navbar-brand-box  style-bgr">
                    <router-link tag="a" to="/" class="logo logo-dark">
                        <span class="logo-sm">
                            <img src="@/assets/images/logo-sm.png" alt width="60" />
                        </span>
                        <span class="logo-lg">
                            <img src="@/assets/images/logo.png" alt height="17" />
                        </span>
                    </router-link>

                    <router-link tag="a" to="/" class="logo logo-light">
                        <span class="logo-sm">
                            <img src="@/assets/images/logo-sm.png" alt :width="widthWindow" />
                        </span>
                        <span class="logo-lg">
                            <img src="@/assets/images/logo.png" alt height="35" />
                            <span class="ml-2" style="color: #fff; font-size: 17px; cursor: pointer;">Kết nối - Xã thông
                                minh</span>
                        </span>
                    </router-link>
                </div>
                <button id="vertical-menu-btn" type="button" class="btn btn-sm px-3 font-size-16 header-item"
                    @click="toggleMenu">
                    <i class="fa fa-fw fa-bars"></i>
                </button>
            </div>

            <div class="d-flex">
                <b-dropdown ref="hide" right variant="black" toggle-class="header-item">
                    <template v-slot:button-content>
                        <img v-if="!getAvatar" class="rounded-circle header-profile-user"
                            src="../assets/images/no-image.png" />
                        <img v-else :src="getAvatar" class="rounded-circle header-profile-user">
                        <span class="d-none d-xl-inline-block ml-1">{{ getUser ? getUser : 'User' }}</span>
                        <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
                    </template>
                    <span @click="hide_dropdown">
                        <a class="dropdown-item" href="javascript: void(0);" @click="goToProfile">
                            <i class="bx bx-user font-size-16 align-middle mr-1"></i> Thông tin cá nhân
                        </a>
                    </span>
                    <span @click="hide_dropdown">
                        <a class="dropdown-item" href="javascript: void(0);" @click="goToChangePassword">
                            <i class="bx bx-edit font-size-16 align-middle mr-1"></i> Đổi mật khẩu
                        </a>
                    </span>
                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item text-danger" href="javascript: void(0);" @click="tryToLogout">
                        <i class="bx bx-power-off font-size-16 align-middle mr-1 text-danger"></i> Đăng xuất
                    </a>
                </b-dropdown>
                <header-notify />
            </div>
        </div>
    </header>
</template>

<script>
import HeaderNotify from './datatable/header-notify';
import { httpPreview } from "../_utils/httpPreview";

import {
    Base64
} from 'js-base64'
import {
mapMutations,
    mapState
} from "vuex";
/**
 * Nav-bar Component
 */
export default {
    components: {
        HeaderNotify
    },
    data() {
        return {
            widthWindow: 40
        }
    },
    computed: {
        ...mapState('user',['name', 'avatar']),
        getUser() {
            let userData = localStorage.getItem('token').split('.')[1]
            let userInfo = JSON.parse(Base64.decode(userData))
            this.setName(userInfo.name)
            return this.name
        },
        getAvatar() {
            let userData = localStorage.getItem('token').split('.')[1]
            let userInfo = JSON.parse(Base64.decode(userData))
            this.setAvatar(userInfo.avatar)
            if (this.avatar.length === 0) {
                return this.avatar;
            }
            if (this.avatar.startsWith(`http`)) return this.avatar
            if (this.avatar != "" && !this.avatar.startsWith(`http`))
                return httpPreview + this.avatar
            return this.avatar;
        }
    },
    methods: {
        ...mapMutations('user', ['setName', 'setAvatar']),
        hide_dropdown() {
            this.$refs.hide.hide();
        },
        toggleMenu() {
            this.$parent.toggleMenu();
        },
        toggleRightSidebar() {
            console.log('navbar toggleRightSidebar clicked');
            this.$parent.toggleRightSidebar();
        },
        initFullScreen() {
            document.body.classList.toggle('fullscreen-enable');
            if (
                !document.fullscreenElement &&
                /* alternative standard method */
                !document.mozFullScreenElement &&
                !document.webkitFullscreenElement
            ) {
                // current working methods
                if (document.documentElement.requestFullscreen) {
                    document.documentElement.requestFullscreen();
                } else if (document.documentElement.mozRequestFullScreen) {
                    document.documentElement.mozRequestFullScreen();
                } else if (document.documentElement.webkitRequestFullscreen) {
                    document.documentElement.webkitRequestFullscreen(
                        Element.ALLOW_KEYBOARD_INPUT,
                    );
                }
            } else {
                if (document.cancelFullScreen) {
                    document.cancelFullScreen();
                } else if (document.mozCancelFullScreen) {
                    document.mozCancelFullScreen();
                } else if (document.webkitCancelFullScreen) {
                    document.webkitCancelFullScreen();
                }
            }
        },
        async goToProfile() {
            await this.$router.push({
                name: 'admin.profile'
            }).catch(err=>err)
        },
        async goToChangePassword() {
            await this.$router.push({
                name: 'admin.change-password'
            }).catch(err=>err)
        },
        ...mapState('account', ['status']),
        async tryToLogout() {
            localStorage.clear()
            await this.$router.push({
                name: 'auth.login'
            })
            var result = document.getElementById('alert');
            if (result) {
                if (result.classList.includes("alert")) {
                    result.classList.remove("alert");
                }
            }
        },
        windowSize() {
            let widthScreen = window.innerWidth;
            if(widthScreen <= 992) {
                this.widthWindow = 0
            }
            else {
                this.widthWindow = 40
            }
        }
    },
    created() {
        this.windowSize();
        window.addEventListener('resize', this.windowSize);
    }
};
</script>

<style scoped>
.style-bgr {
    background-color: #292848 !important;
    padding: 0 !important;
    height: 52px;
}
</style>
