var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"sidebar-menu"}},[_c('ul',{staticClass:"metismenu list-unstyled",attrs:{"id":"side-menu"}},[_vm._l((_vm.menu),function(item,index){return [(item.type === 'header')?void 0:(_vm.hasPermission(item))?[(!item.hasOwnProperty('children'))?_c('li',{key:index},[_c('router-link',{staticClass:"side-nav-link-ref",attrs:{"exact":"","to":item.to,"active-class":"mm-active"}},[_c('i',{class:item.icon}),_c('span',[_vm._v(_vm._s(item.title))])])],1):_c('li',{key:index,class:_vm.isActive(item.active)},[_c('a',{staticClass:"has-arrow",class:_vm.isActive(item.active),attrs:{"href":"javascript: void(0);"}},[_c('i',{class:item.icon}),_c('span',[_vm._v(_vm._s(item.title))])]),_c('ul',{staticClass:"sub-menu mm-collapse",class:{'mm-show': item.active},attrs:{"aria-expanded":"false"}},[_vm._l((item.children),function(child,child_index){return [(_vm.hasPermission(child))?[(!child.hasOwnProperty('children'))?_c('li',{key:child_index},[_c('router-link',{staticClass:"side-nav-link-ref",class:{'color-white': _vm.$route.name==child.name || 
                                    child.name == _vm.$route.name.replace('edit', 'list') && child.name !='admin.users.resident' ||
                                    child.name == _vm.$route.name.replace('detail', 'list') || 
                                    child.name == _vm.$route.name.replace('result', 'archived') ||
                                    child.name == _vm.$route.name.replace('updateStatus', 'archived') ||
                                    child.name == _vm.$route.name.replace('view','list') ||
                                    child.name == _vm.$route.name.replace('statistic','list') || 
                                    ( _vm.$route.name.includes('config') && child.name == _vm.$route.name.replace('create','list'))
                                    },attrs:{"exact":"","to":{name: child.name}}},[_vm._v(" "+_vm._s(child.title)+" "),(child && child.key_number_articles)?_c('span',{staticClass:"badge badge-pill badge-success float-right",attrs:{"id":child.key_number_articles + '_' + child.count_type}},[_vm._v("0")]):_vm._e()])],1):_c('li',{key:child_index,class:_vm.isActive(child.active)},[_c('a',{staticClass:"has-arrow",class:_vm.isActive(child.active),attrs:{"href":"javascript: void(0);","aria-expanded":"true"}},[_vm._v(" "+_vm._s(child.title)+" ")]),_c('ul',{staticClass:"sub-menu mm-collapse",class:{'mm-show': child.active},attrs:{"aria-expanded":"true"}},[_vm._l((child.children),function(child2,child2_index){return [(_vm.hasPermission(child2))?_c('li',{key:child2_index},[_c('router-link',{attrs:{"exact":"","to":child2.to,"active-class":"mm-active"}},[_vm._v(" "+_vm._s(child2.title)+" ")])],1):_vm._e()]})],2)])]:_vm._e()]})],2)])]:_vm._e()]})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }